import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContentContainer from "../../../components/container/ContentContainer";
import ContactExpert from "../../../components/controls/ContactExpert";
import Footer from "../../../components/sections/Footer";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";
import { useStaticQuery, graphql } from "gatsby";
import TitleDiv from "../../../components/controls/TitleDiv";
import ButtonAction from "../../../components/controls/ButtonAction";
import Thumbnail from "../../../components/controls/Thumbnail";
import SimpleReactLightbox from "simple-react-lightbox";

const LacqueredBoardsPage = () => {
  const images = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "oneskin-page-cover.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      glossy: allFile(
        filter: { relativeDirectory: { eq: "lacquered-boards/glossy" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      matte: allFile(
        filter: { relativeDirectory: { eq: "lacquered-boards/matte" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            name
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                quality: 100
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `);
  const path = ["Lacquered Boards"];

  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData title="Lacquered Boards" />
      <Header />
      <TitleDiv
        parentText="Products"
        title="Lacquered Boards"
        className="mb-8"
        path={path}
      />

      <ContentContainer
        className="w-full max-w-content md:mx-5 transform bg-bottom scale-x-flip flex items-center justify-center"
        opacityClassName="bg-opacity-60"
        image={images.backGround}
      >
        <div className="text-white my-20 transform scale-x-flip text-center text-3xl space-y-4 w-full max-w-xl">
          <p className="font-bold">OneSkin Inspiring Panels</p>
          <p className="text-base pt-5">
            OneSkin has been created to supply worldwide markets with high
            quality lacquered boards that open new possibilities in the
            furniture industry, interior decorations, paneling, and other
            architectural purposes. We achieve this objective with our
            innovative and state-of-the-art production line for lacquering and
            unique surface treatment of different kinds of boards and panels.
            Thus, OneSkin panels are available with two exclusive finishes: high
            gloss and soft touch super matte.
          </p>
          <ButtonAction to="/contact" className="mt-10 bg-actionBlue px-16">
            Request free quote
          </ButtonAction>
        </div>
      </ContentContainer>

      <ContentContainer className="bg-gray5 text-center py-12 mt-14">
        <h2>Collection</h2>
      </ContentContainer>
      <ContentContainer className="bg-gray2 py-12 mb-9">
        <div className="flex flex-col text-center items-center md:px-5 lg:px-20">
          <p className="text-3xl">Prime Collection</p>
          <hr className="border-blue4 border-t-2 mt-4 w-56" />
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full pt-14 gap-x-0 gap-y-11">
            <StaticImage
              alt="OneSkin-Prime-Collection"
              placeholder="blurred"
              quality={100}
              src="../../../images/lacquered-boards/oneskin-prime-collection.jpg"
            />
            <div className="bg-gray1 py-20 px-12 text-left">
              <p>
                OneSkin panels are intended to inspire the creation of unique
                solutions in furniture and architectural design. Our exclusive
                finishes “high gloss” and “soft touch super matte”, paired with
                a wide range of basic colors and special metallic colors as well
                as abstract, stone and wood finishes can be used to create any
                style or trend as desired by our customers, whether it is a
                modern kitchen, a vintage bedroom, an industrial office area, or
                a luxurious hotel lobby.
                <br />
                Industrial customers will undeniably appreciate the elasticity
                of our lacquers making OneSkin panels extremely easy to cut and
                drill enabling a perfect transition from panels to end products.
                OneSkin panels are solvent and PVC free.
              </p>
            </div>
          </div>
        </div>
      </ContentContainer>

      <ContentContainer className="bg-gray5 text-center py-12 mt-14">
        <h2>Collection</h2>
      </ContentContainer>
      <ContentContainer className="bg-gray2 py-12 mb-9">
        <div className="flex flex-col text-center items-center md:px-5 lg:px-20">
          <p className="text-3xl">OneSkin High Gloss</p>
          <hr className="border-blue4 border-t-2 mt-4 w-56" />
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full pt-14 gap-x-0 gap-y-11">
            <StaticImage
              alt="OneSkin Prime Collection"
              placeholder="blurred"
              quality={100}
              src="../../../images/lacquered-boards/oneskin-high-gloss.jpg"
            />
            <div className="bg-gray1 py-20 px-12 text-left flex flex-col space-y-6">
              <p>
                {`OneSkin is proud to say that our high gloss surfaces (gloss
                level > 90 GU) has the best results in micro scratch resistance
                (Martindale test with scotch-brite), as well as very high
                scratch and abrasion resistance, adhesion resistance, color
                stability, and chemical resistance. All of these technical
                characteristics translate into a product quality which is valued
                by all users of OneSkin high gloss panels.`}
              </p>
              <p>COLORS:</p>
              <p>Solid Colors, Woods, Stones, Metallics, Oxide, Stucco</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-5 sm:px-20 mt-11 items-center sm:items-start">
          <p className="font-bold">Available colors for OneSkin High Gloss</p>
          <hr className="border-blue4 border-t-2 mt-2 w-56" />
        </div>
        <SimpleReactLightbox>
          <div className="grid gap-y-12 gap-x-5 sm:gap-x-14 grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-16 mx-5 sm:mx-20 text-center items-start">
            {images.glossy.edges.map((thumbnail, index) => {
              return (
                <Thumbnail
                  key={`gallery${index}`}
                  hiRes={
                    thumbnail.node.childImageSharp.gatsbyImageData.images
                      .fallback.src
                  }
                  name={thumbnail.node.name}
                  thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                  thumbnailClassName="max-h-galleryThumbNailRectangleSmall md:max-h-galleryThumbNailRectangle min-h-galleryThumbNailRectangleSmall md:min-h-galleryThumbNailRectangle"
                />
              );
            })}
          </div>
        </SimpleReactLightbox>
      </ContentContainer>

      <ContentContainer className="bg-gray5 text-center py-12 mt-14">
        <h2>Collection</h2>
      </ContentContainer>
      <ContentContainer className="bg-gray2 py-12 mb-9">
        <div className="flex flex-col text-center items-center md:px-5 lg:px-20">
          <p className="text-3xl">OneSkin Super Matte</p>
          <hr className="border-blue4 border-t-2 mt-4 w-56" />
          <div className="grid grid-cols-1 lg:grid-cols-2 w-full pt-14 gap-x-0 gap-y-11">
            <StaticImage
              alt="OneSkin Super Matte"
              placeholder="blurred"
              quality={100}
              src="../../../images/lacquered-boards/oneskin-super-matte.jpg"
            />
            <div className="bg-gray1 py-20 px-12 text-left flex flex-col space-y-6">
              <p>
                {`OneSkin super matte panels (gloss level < 5 GU) have an exceptional natural, soft touch and warm feeling due to application of special varnishes and a unique surface treatment with a special physical process.
In addition, the scratch, fingerprints and gloss spots free surface characteristics of this innovative and exceptional product sets OneSkin super matte panels apart from any other products in the market.`}
              </p>
              <p>COLORS:</p>
              <p>Solid Colors, Woods, Stones, Metallics, Oxide, Stucco</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-5 sm:px-20 mt-11 items-center sm:items-start">
          <p className="font-bold">Available colors for OneSkin Super Matte</p>
          <hr className="border-blue4 border-t-2 mt-2 w-56" />
        </div>
        <SimpleReactLightbox>
          <div className="grid gap-y-12 gap-x-5 sm:gap-x-14 grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-16 mx-5 sm:mx-20 text-center items-start">
            {images.matte.edges.map((thumbnail, index) => {
              return (
                <Thumbnail
                  key={`gallery${index}`}
                  hiRes={
                    thumbnail.node.childImageSharp.gatsbyImageData.images
                      .fallback.src
                  }
                  name={thumbnail.node.name}
                  thumbnail={thumbnail.node.childImageSharp.gatsbyImageData}
                  thumbnailClassName="max-h-galleryThumbNailRectangleSmall md:max-h-galleryThumbNailRectangle min-h-galleryThumbNailRectangleSmall md:min-h-galleryThumbNailRectangle"
                />
              );
            })}
          </div>
        </SimpleReactLightbox>
      </ContentContainer>

      <ContentContainer className="bg-gray2 py-14 sm:py-24 mb-9 flex text-center text-lg md:text-xl px-5 xl:px-102 space-y-8">
        <i>
          “OneSkin panels are characterized with an outstanding visual quality:
          mirror and depth effect for high gloss surfaces and astonishing warm,
          soft and natural feeling for super matte surfaces.”
        </i>
        <p>- OneSkin</p>
      </ContentContainer>

      <ContactExpert />
      <Footer />
    </div>
  );
};

export default LacqueredBoardsPage;
